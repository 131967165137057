/*
 Description:  Silubi Tracer App Scan / Search Results Style
 Author:       WRT Dev Team (T Disney, M Babenko)
 Author URI:   https://www.silubi.com/
 Version:      1.0
 Domain:       trace.silubi.io
*/

/* ----------------------------- Style Sheet ----------------------------- */
/* INDEX:
- Scan Result Formatting
- Responsive Formatting
*/

/* ----------------------------- Scan Result Formatting ----------------------------- */

.productSplash {
	width: 520px;
	min-height: 80vh;
	margin: 0 auto;
	border-radius: 15px 15px 0px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	background-size: cover;
	background-position: center top;
}

.productTitle {
	width: 100%;
	min-height: 100px;
	color: #ffffff;
	font-size: larger;
	font-weight: bold;
	background-image: linear-gradient(rgb(0, 0, 0, 0), rgb(0, 0, 0, 0.8));
}

.productExploreTab {
	width: 100%;
	height: 55px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	background-color: rgb(0, 0, 0, 0.8);
	background-image: url(../../images/exploreTab.svg);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center 6px;
}

.productQuickStats {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    grid-gap: 0px;
    margin: 0px auto 20px auto;
}

.productQuickStats-score {grid-row: 1 / 2; grid-column: 1 / 2;}
.productQuickStats-arrows {grid-row: 1 / 2; grid-column: 2 / 3;}
.productQuickStats-origin {grid-row: 1 / 2; grid-column: 3 / 4;}


.productData {
	min-height: calc(100vh - 120px);
	padding: 10px 14px;
	border-radius: 15px;
	background-color: #dddddd;
	background-image: linear-gradient(160deg, #f5f5f5, #f0f0f0, #f7eee6, #dddddd);
}

.productDataTitle {
	width: 80%;
	color: #2a3c53;
	margin: 5px auto;
	font-size: larger;
	font-weight: bold;
}

.productPhoto {
	width: 100%;
    height: 400px;
	margin: 15px 0px 0px 0px;
    border-radius: 10px;
	background-position: center center;
	background-size: cover;
}

.pageSegment {
	margin: 20px 0px 0px 0px;
	padding: 2px 0px 0px 0px;
	font-weight: bold;
	border-top: 2px solid #dddddd;
}

.dataSegment {
	padding: 15px 20px;
	margin: 10px auto;
	text-align: left;
	line-height: 1.2;
	background-color: #ffffff;
	border-radius: 10px;
}

.dataSegmentdata {
	display: grid;
    grid-template-columns: 15% auto;
    grid-gap: 0px;
	text-align: left;
}
.dataSegmentdata-left {grid-row: 1 / 2; grid-column: 1 / 2;}
.dataSegmentdata-left img {width: 30px; padding: 5px 0px 0px 0px;}
.dataSegmentdata-right {grid-row: 1 / 2; grid-column: 2 / 3; text-align: left;}
.dataSegmentdata-right img {padding: 5px 0px 0px 0px;}

.docSegment {
	padding: 6px 10px;
	margin: 10px auto;
	line-height: 1.2;
	background-color: #ffffff;
	border-radius: 10px;
	cursor: pointer;
}
.docSegment:hover {
	color: #0d6d8a;
	font-weight: bold;
}

.docSegmentdata {
	display: grid;
    grid-template-columns: 35px auto;
    grid-gap: 0px;
	align-items: center;
	text-align: left;
}
.docSegmentdata-left {grid-row: 1 / 2; grid-column: 1 / 2;}
.docSegmentdata-left img {width: 18px; padding: 0px 0px 0px 0px;}
.docSegmentdata-right {grid-row: 1 / 2; grid-column: 2 / 3; text-align: left;}
.docSegmentdata-right img {padding: 0px 0px 0px 0px;}

.socials {
	height: 30px;
	margin: 10px;
}

.document-image {width: 100%;}

.exploreSkip {transition: 2s ease;}

/* ----------------------------- Responsive Formatting ----------------------------- */

@media (max-width: 1024px) {
}

@media (max-width: 768px) {
	.productSplash {width: 100%;}
	.dataSegment {width: 520px;}
	.docSegment {width: 520px;}
}

@media (max-width: 480px) {
	.productQuickStats {width: 100%;}
	.dataSegment {width: calc(100% - 40px);}
	.docSegment {width: calc(100% - 20px);}
}

@media (max-width: 320px) {

}