/*
Description:  Silubi Tracer App Mapbox Style
Author:       WRT Dev Team (T Disney, M Babenko)
Author URI:   https://www.silubi.com/
Version:      1.0
Domain:       trace.silubi.io
*/

/* ----------------------------- Style Sheet ----------------------------- */
/* INDEX:
- Mapbox Formatting
- Responsive Formatting
*/

/* ----------------------------- Mapbox Formatting ----------------------------- */

div.mapboxgl-map {
    /* width: 500px; */
    height: 250px;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid #dddddd;
  }
  
  div.mapboxgl-control-container {
    display: none;
  }

/* ----------------------------- Responsive Formatting ----------------------------- */

@media (max-width: 1024px) {
}
@media (max-width: 768px) {
  div.mapboxgl-map {
      max-height: 200px;
      width: 100%;}
}
@media (max-width: 480px) {
  div.mapboxgl-map {width: 100%;}
}
@media (max-width: 320px) {
  div.mapboxgl-map {width:100%;}
}

/* ----------------------------- Marker Styling ----------------------------- */
/* .marker-clicked {
  background-image: url('https://img.icons8.com/office/80/000000/marker.png');
  background-size: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}
.marker-unclicked {
  background-image: url('https://img.icons8.com/ultraviolet/80/000000/marker.png');
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
} */
/* ----------------------------- Marker Styling ----------------------------- */