/*
 Description:  Silubi Tracer App Style
 Author:       WRT Dev Team (T Disney, M Babenko)
 Author URI:   https://www.silubi.com/
 Version:      2.0
 Domain:       trace.silubi.io
*/

/* ----------------------------- Style Sheet ----------------------------- */
/* INDEX:
- Global Formatting
- Page Section Formatting
- Result Section Formatting
-- Map Visualisation
-- Timeline Visualisation
-- Inventory Visualisation
- Responsive Formatting
*/

/* ----------------------------- Global Formatting ----------------------------- */
.App {
  height: 100px;
  color: #fff;
  font-family: "Segoe UI", "Open Sans", Verdana, Arial, Helvetica, sans-serif;
  text-decoration: none;
  font-weight: normal;
  border: 0px;
  margin: 0 auto;
}

h1 {}
h2 {}
h3 {}
h4 {}
h5 {}

p {
  margin: 5px;
  line-height: 1.25;
  word-wrap: break-word;
}
p.product {
  font-size: larger;
  font-weight: 600;
  line-height: 0.90;
  margin: 5px 5px 15px 5px;
}
p.location {
  font-size: medium;
}
/* p.scanRef {
  max-width: 90%;
  margin: 5px auto;
  font-family: monospace;
  font-size: medium;
} */

a {
  color: #fff;
  font-family: "Segoe UI", "Open Sans", Verdana, Arial, Helvetica, sans-serif;
  text-decoration: none;
  border: 0px;
  cursor: pointer;
}
a:hover {
  color: #6ea1ab;
}

.App-link {
  color: #748892;
  display: none;
}

.data-link {
  color: #1e212e;
  font-weight: bold;
  cursor: pointer;
}
.data-link:hover {color: #0d6d8a;}

.back-link {
  color: #6ea1ab;
  position: absolute;
  cursor: pointer;
}
.back-link:hover {
  color: #adb7be;
}
.back-link .material-icons-round {font-size: 21px;}
.back-link .material-icons {font-size: 21px;}


img.silubi-logo {
  width: 100px;
  margin: 20px 0px 0px 20px;
}


.blue-text {color: #1e212e; font-weight: bold;}
.grey-text {color: #adb7be;}
.orange-text {color: coral;}
.teal-text {color: #0d6d8a;}

.data-small {
  color: #6ea1ab;
  font-size: small;
  font-weight: normal;
}


/* ----------------------------- Page Section Formatting ----------------------------- */

.App-header {
  width: 100%;
  height: 70px;
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-size: 14px;
  border: 0px;
  background-color: #F5F5F5;
}

.App-body {
  width: 520px;
  min-height: calc(100vh - 100px);
  color: #5b5b5b;
  margin: 0 auto 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 11px 10px 10px #dddddd;
}

.loader {
  margin: 50px auto;
  -webkit-animation: rotate 8s ease 5s infinite;
  -moz-animation: rotate 8s ease 5s infinite;
  animation: rotate 8s ease 5s infinite;
}
@-moz-keyframes rotate {
  0% { -moz-transform: scaleX(1); }
  50% { -moz-transform: scaleX(-1); }
  }
  @-webkit-keyframes rotate {
  0% { -webkit-transform: scaleX(1); }
  50% { -webkit-transform: scaleX(-1); }
  }
  @keyframes rotate {
  0% { -webkit-transform: scaleX(1); transform: scaleX(1); }
  50% { -webkit-transform: scaleX(-1); transform: scaleX(-1); }
  }

.loader img {
  height: 300px;
}

.content {
  width: 520px;
  min-height: calc(100vh - 100px);
  margin: 0px;
  padding: 0px;
  text-align: center;
  justify-content: center;
  border: 0px;
  border-radius: 15px 15px 0px 0px;
}

.messaging {
  width: calc(100% - 40px);
  text-align: center;
  border: 0px;
  margin: 30px auto;
  padding: 0px;
  font-size: large;
}

/* .button-area {
  height: 350px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 25px;
  background-image: url(../images/supplychain-ilu.svg);
  background-size: auto;
  background-repeat: repeat-x;
  background-position: center bottom;
  border: 0px;
  margin: 0 auto;
  padding: 0px;
} */

/* .scanner-area {
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  background-image: url(../images/supplychain-ilu.svg);
  background-size: auto;
  background-repeat: repeat-x;
  background-position: center bottom;
  border: 0px;
  margin: 0 auto;
  padding: 0px;
} */

.App-footer {
  width: 100%;
  min-height: 25px;
  margin: 0px;
  padding: 5px 0px 0px 0px;
  color: #adb7be;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.5px;
  border: 0px;
  background-color: #2a3c53;
  background-image: linear-gradient(179.5deg, #1e212e, #0d6d8a, #6ea1ab); /* Tim: Silubi Blue gradient */
}


/* ----------------------------- Result Section Formatting ----------------------------- */

/* div.price {
  font-weight: bold;
  font-size: large;
  background-color: rgb(147, 181, 180, 0.3);
  padding: 5px 0px;
} */

/* div.result-vis {
  min-height: calc(100vh - 580px);
  color: #5b5b5b;
  background-color: #fff;
  background-image: linear-gradient(#fff, #EBECEC);
  border: 0px;
  border-radius: 0px;
  margin: 0 auto;
  padding: 5px 0px 20px 0px;
} */


/* --------------- Map Visualisation (see mapbox-gl.css) --------------- */

div.map-vis {
  max-width: 540px;
  margin: 10px auto 0px auto;
}

div.map-vis-error {
  display: none;
}


/* --------------- Timeline Visualisation --------------- */

div.timeline-vis {
  width: 500px;
  margin: 0px auto 0px auto;
  padding: 0px 0px 40px 0px;
  text-align: left;
  display: grid;
  grid: auto auto / 25px;
}

div.timeline-start {
  width: 20px;
  grid-column: 1 / 2;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../images/timeline-gfx-top.svg);
  background-repeat: no-repeat;
  background-position: center 27px;
  cursor: pointer;
}

div.timeline-node  {
  width: 20px;
  grid-column: 1 / 2;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../images/timeline-gfx-mid.svg);
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

div.timeline-stop  {
  width: 20px;
  grid-column: 1 / 2;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../images/timeline-gfx-bot.svg);
  background-repeat: no-repeat;
  background-position: center -285px;
  cursor: pointer;
}

div.timeline-step {
  grid-column: 2 / 3;
  margin: 10px;
}

p.timeline-agent {
  margin: 2px;
  font-weight: bold;
}

p.timeline-data {
  color: #6ea1ab;
  margin: 2px;
  font-size: small;
}

.timeline-link {
  font-weight: bold;
  cursor: pointer;
}

div.timeline-warn  {
  width: 20px;
  grid-column: 1 / 2;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../images/timeline-gfx-warn.svg);
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

p.timeline-agent-warn {
  color: #adb7be;
  margin: 2px;
  font-weight: normal;
  cursor: pointer;
}

p.timeline-data-warn {
  color: #6ea1ab;
  margin: 2px;
  font-size: medium;
}


/* --------------- Inventory Visualisation --------------- */

/* div.inventory-vis {
  max-width: 540px;
  margin: 10px auto 0px auto;
  padding: 0px 0px 12px 0px;
}

.inventoryTable {
  width: 90%;
  margin: 0px auto 12px auto;
  border-spacing: 0px;
  border-collapse: collapse;
}

tr:nth-child(even) {
  background-color: rgb(0, 0, 0, 0.05);
}

td:nth-child(even) {
  background-color: rgb(0, 0, 0, 0.025);
}

.inventoryTableHeader {
  text-align: left;
}

.inventoryTableHead {
  color: #fff;
  padding: 4px 8px 8px 8px;
  border-bottom: 5px solid #fff;
  background-color: #1c395a;
}

.inventoryTableHeadIcon {
  color: #fff;
  padding: 0px 4px;
  border-bottom: 5px solid #fff;
  background-color: #1c395a;
  text-align: center;
}
.inventoryTableHeadIcon .material-icons-round {font-size: 18px; padding: 0px; display: flex; align-items: center; justify-content: center;}
.inventoryTableHeadIcon .material-icons {font-size: 18px; padding: 0px; display: flex; align-items: center; justify-content: center;}

.inventoryTableRow {}

.inventoryTableData {
  padding: 4px 8px;
  vertical-align: middle;
}
.inventoryTableData .material-icons-round {font-size: 20px;}
.inventoryTableData .material-icons {font-size: 20px;}

.inventoryTableData a {
  color: #6ea1ab;
  font-weight: 500;
}

.inventoryTableLotMarker {border-bottom: 8px solid #fff;}
.inventoryTableLotMarkerData {text-align: left;}

.inventoryCloser {
  color: firebrick;
  padding: 3px 0px 0px 0px;
  font-size: small;
}
.inventoryCloser .material-icons-round {font-size: 20px; cursor: pointer;}
.inventoryCloser .material-icons {font-size: 20px; cursor: pointer;}

img.closeIcon {
  width: 16px;
  cursor: pointer;
}

.inventoryTableDataIcon {
  padding: 3px 0px 0px 0px;
  font-size: small;
}
.inventoryTableDataIcon .material-icons-round {font-size: 20px;}
.inventoryTableDataIcon .material-icons {font-size: 20px;} */


/* ----------------------------- Responsive Formatting ----------------------------- */

@media (max-width: 1024px) {
}

@media (max-width: 768px) {
  .App-body {
    width: 100%;
    margin: 0 auto;
    box-shadow: 0px 12px 10px 10px #1e212e;
  }
  .content {width: 100%;}
}

@media (max-width: 480px) {
  /* div.result-vis {border-radius: 0px 0px 20px 20px;} */
  div.timeline-vis {width: calc(100% - 10px);}
  div.map-vis {width: 100%;}
  .inventoryTable {font-size: small;}

  p.scanRef {
    padding: 0px 3px 5px 3px;
    text-align: left;
    border-bottom: 1px solid #adb7be;
  }
}

@media (max-width: 320px) {
  .grey-text {color: #adb7be; font-size: small;}

  .inventoryTable {width: 95%; font-size: 12px;}
}
