/*
 Description:  Silubi Tracer App Style
 Author:       WRT Dev Team (T Disney, M Babenko)
 Author URI:   https://www.silubi.com/
 Version:      1.1
 Domain:       trace.silubi.io
*/

/* ----------------------------- Style Sheet ----------------------------- */
/* INDEX:
- Global Formatting
*/

/* ----------------------------- Global Formatting ----------------------------- */

html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: #F5F5F5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
